import React, { useCallback, useContext } from "react"

import MinimalLayout from "../components/minimalLayout"
import SEO from "../components/seo"
import ThemeContext from "../context/ThemeContext"
import { Link } from "gatsby"

import styled from "styled-components"

const Container = styled.section`
  --accent: ${props => (props.accent ? props.accent : "#FFE747")};
  --foreground: ${props => (props.foreground ? props.foreground : "black")};
  --background: ${props => (props.background ? props.background : "white")};

  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 160px 0 80px;
  color: ${props => (props.foreground ? props.foreground : "black")};
  background-color: ${props => (props.background ? props.background : "white")};
  position: relative;
  transition: background-color 0.5s;
  align-items: flex-start;
  width: 100%;
`

const NotFoundPage = () => {
  const themeContext = useContext(ThemeContext)

  useCallback(() => {
    themeContext.setColors({
      foreground: "#333",
      background: "#FFF",
      accent: "#FFE747",
    })
  }, [themeContext])

  return (
    <MinimalLayout>
      <Container foreground="#333" background="#FFF" accent="#FFE747">
        <SEO title="HUCH!" />

        <div className="layout-container">
          <h1 className="superHeadline">Wo sind wir denn da?</h1>
          <p className="paragraph paragraph--big margin-top-l">
            Jetzt aber schnell wieder zurück!
          </p>
          <Link to="/" className="link">
            Jetzt.
          </Link>
        </div>
      </Container>
    </MinimalLayout>
  )
}

export default NotFoundPage
